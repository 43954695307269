
import {defineComponent, ref} from "vue";
import {Form, ErrorMessage} from "vee-validate";
import UserService from "@/core/services/UserService";
import {Field} from "vee-validate";
import * as Yup from "yup";

export default defineComponent({
  name: "Invite",
  components: {Form, Field, ErrorMessage},
  props: {
    token: {type: String},
  },
  setup() {
    const validate = Yup.object().shape({
      firstName: Yup.string().required().label("First Name"),
      lastName: Yup.string().required().label("Last Name"),
      password: Yup.string().required().label("Password"),
      confirmPassword: Yup.string().required().label("confirmPassword"),
    });
    const model = ref<any>({lastName: '', firstName: '', password: '', token: '', confirmPassword: '', timezone: ''});
    const registered = ref(false)
    return {
      validate,
      registered,
      model,
    }
  },
  methods: {
    formSubmit() {
      this.model.token = this.token
      this.model.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const submitButton = this.$refs.submitButton as typeof HTMLButtonElement.prototype;
      submitButton.disabled = true
      submitButton?.setAttribute("data-kt-indicator", "on");
      UserService.completeInvite(this.model).then(() => {
        this.registered = true;
      }).catch(eror => {
        const finalError = {};
        eror.data.errors.forEach(item => {
          finalError[item.field] = [item.message]
        })
        const form = this.$refs.registerForm as typeof HTMLFormElement.prototype
        form.setErrors(finalError);
      }).finally(() => {
        submitButton.disabled = false;
        submitButton?.removeAttribute("data-kt-indicator");
      })
    }
  }
})
